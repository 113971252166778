<template>
    <div class="container-fluid mt-header">
    <div class="row" id="body-sidemenu">
      <!-- Sidebar -->
      <div id="sidebar-container" class="border-right sidebar-expanded sidebar-fixed d-none d-block">
        <!-- Media top -->
        <div class="media d-none d-md-block">
          <div class="card-body px-0 px-sm-3 pb-2">
            <img src="../../assets/img/avatar5.png" class="align-self-start float-left float-sm-none rounded-circle img-fluid mr-2 w-25">
            <div class="media-body mt-4 mt-sm-2">
              <p class="mb-0">Nama Lengkap User</p>
              <p class="card-text mb-1 small">Administrator</p>
            </div>
          </div>
        </div>

        <button class="btn btn-outline-secondary ml-3 mt-3 d-md-none" data-toggle="collapse" data-target="#submenu">Submenu</button>
        <ul id="submenu" class="list-group list-group-flush border-top border-bottom py-2 collapse-sm collapse">
          <li class="list-group-item border-0 p-0"><a href="./admin-dasbor.html" class="d-block bg-hover py-1 pl-3">Dasbor</a></li>
          <li class="list-group-item border-0 p-0 active"><a href="./admin-notifikasi.html" class="d-block bg-hover py-1 pl-3">Notifikasi</a></li>
        </ul>
      </div><!-- sidebar -->
    
      <!-- MAIN -->
      <div id="main-content" class="col with-fixed-sidebar">
        <div class="row">
          <nav aria-label="breadcrumb" class="w-100">
            <ol class="breadcrumb mb-0 rounded-0 bg-transparent">
              <li class="breadcrumb-item small"><a href="./admin-dasbor.html">Administrasi</a></li>
              <li class="breadcrumb-item small active" aria-current="page">Notifikasi</li>
            </ol>
          </nav>
        </div>
  
        <div class="h4">Notifikasi</div>
    
        <div class="row">
          <div class="col-12">
            <div class="alert alert-secondary alert-dismissible fade show" role="alert">
              <button type="button" class="close" data-dismiss="alert">&times;</button>
              <strong>Alert!</strong> You should <a href="javascript:void(0);" class="alert-link" title="Click to go to this message">read this message</a>.
              <button id="submitButton" class="btn btn-sm btn-light border ml-10 py-0 mt-n1" type="submit" title="Action..."><i class="fa fa-user mr-1"></i>Action</button>
            </div>
          </div> <!-- .col -->
        </div> <!-- .row -->

        <div class="row">
          <div class="col-12">
            <div class="card bg-light">
              <div class="card-body p-10">
                <form class="form-inline" action="javascript:void(0);">
                  <label for="f1" class="mt-2 mt-sm-0 mr-sm-2">Filter:</label>
                  <input type="text" class="form-control form-control-sm mr-sm-2" placeholder="Kata kunci pencarian..." id="f1">
                  <label for="f2" class="mt-2 mt-sm-0 mr-sm-2">Waktu:</label>
                  <input type="text" class="form-control form-control-sm mr-sm-2" placeholder="DD/MM/YY" id="f2">
                  <label for="f3" class="mt-2 mt-sm-0 mr-sm-2"> - </label>
                  <input type="text" class="form-control form-control-sm mr-sm-2" placeholder="DD/MM/YY" id="f3">
                  <label for="sel1" class="mt-2 mt-sm-0 mr-sm-2">Type:</label>
                  <select class="form-control form-control-sm mr-sm-2" id="sel1">
                    <option>Option 1</option>
                    <option>Option 2</option>
                    <option>Option 3</option>
                    <option>Option 4</option>
                  </select>
                  <label for="sel1" class="mt-2 mt-sm-0 mr-sm-2">Status:</label>
                  <select class="form-control form-control-sm mr-sm-2" id="sel1">
                    <option>Option 1</option>
                    <option>Option 2</option>
                    <option>Option 3</option>
                    <option>Option 4</option>
                  </select>
                  <button type="submit" class="btn btn-sm btn-secondary mt-2 mt-md-0">Filter</button>
                </form>
              </div>
            </div>
          </div>
        </div>
  
        <div class="row">
          <div class="col-12">
            <div class="table-responsive">
              <table class="table table-striped table-sm">
                <thead class="thead-main border text-nowrap">
                  <tr>
                    <th>
                      <div class="custom-control custom-checkbox ml-2">
                        <input type="checkbox" class="custom-control-input" id="selectAll" name="selectAll">
                        <label class="custom-control-label" for="selectAll"></label>
                      </div>
                    </th>
                    <th>ID</th>
                    <th>Kode</th>
                    <th>Nama</th>
                    <th>Tipe</th>
                    <th>Qty</th>
                    <th>Detail</th>
                    <th>Area</th>
                    <th>Status</th>
                    <th>Tanggal</th>
                    <th>Aksi</th>
                  </tr>
                </thead>
                <tbody class="border text-nowrap">
                  <tr>
                    <td>
                      <div class="custom-control custom-checkbox ml-2">
                        <input type="checkbox" class="custom-control-input" id="c1" name="checkbox">
                        <label class="custom-control-label" for="c1"></label>
                      </div>
                    </td>
                    <td>4324</td>
                    <td>43241010700001</td>
                    <td>Lorem Ipsum Dolor</td>
                    <td>B</td>
                    <td>4</td>
                    <td>Sit Amet</td>
                    <td>Adispiscing Excepteur</td>
                    <td><span class="text-success"><i class="fa fa-circle mr-1"></i>OK</span></td>
                    <td>2020/07/12 - 11:22</td>
                    <td class="text-nowrap">
                      <a href="#" class="btn btn-sm color-main py-0" title="View/Edit"><i class="fa fa-edit"></i></a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="custom-control custom-checkbox ml-2">
                        <input type="checkbox" class="custom-control-input" id="c2" name="checkbox">
                        <label class="custom-control-label" for="c2"></label>
                      </div>
                    </td>
                    <td>9455</td>
                    <td>43241010700001</td>
                    <td>Consectetur Adipiscing</td>
                    <td>N</td>
                    <td>2</td>
                    <td>Dos Elit</td>
                    <td>Irure Voluptate</td>
                    <td><span class="text-success"><i class="fa fa-circle mr-1"></i>OK</span></td>
                    <td>2020/07/11 - 08:51</td>
                    <td class="text-nowrap">
                      <a href="#" class="btn btn-sm color-main py-0" title="View/Edit"><i class="fa fa-edit"></i></a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="custom-control custom-checkbox ml-2">
                        <input type="checkbox" class="custom-control-input" id="c3" name="checkbox">
                        <label class="custom-control-label" for="c3"></label>
                      </div>
                    </td>
                    <td>3214</td>
                    <td>43241010700001</td>
                    <td>Sed Do Eiusmod</td>
                    <td>O</td>
                    <td>2</td>
                    <td>Tempor Incididunt</td>
                    <td>July Dooley</td>
                    <td><span class="text-success"><i class="fa fa-circle mr-1"></i>OK</span></td>
                    <td>2020/07/09 - 11:22</td>
                    <td class="text-nowrap">
                      <a href="#" class="btn btn-sm color-main py-0" title="View/Edit"><i class="fa fa-edit"></i></a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="custom-control custom-checkbox ml-2">
                        <input type="checkbox" class="custom-control-input" id="c4" name="checkbox">
                        <label class="custom-control-label" for="c4"></label>
                      </div>
                    </td>
                    <td>4324</td>
                    <td>43241010700001</td>
                    <td>Ut Labore Et Dolore</td>
                    <td>B</td>
                    <td>1</td>
                    <td>Magna Aliqua</td>
                    <td>John Doe</td>
                    <td><span class="text-success"><i class="fa fa-circle mr-1"></i>OK</span></td>
                    <td>2020/07/09 - 22:30</td>
                    <td class="text-nowrap">
                      <a href="#" class="btn btn-sm color-main py-0" title="View/Edit"><i class="fa fa-edit"></i></a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="custom-control custom-checkbox ml-2">
                        <input type="checkbox" class="custom-control-input" id="c5" name="checkbox">
                        <label class="custom-control-label" for="c5"></label>
                      </div>
                    </td>
                    <td>9455</td>
                    <td>43241010700001</td>
                    <td>Enim Ad Minim Veniam</td>
                    <td>P</td>
                    <td>2</td>
                    <td>Nostrud Exercitation</td>
                    <td>Mary Moe</td>
                    <td><span class="text-success"><i class="fa fa-circle mr-1"></i>OK</span></td>
                    <td>2020/07/09 - 11:22</td>
                    <td class="text-nowrap">
                      <a href="#" class="btn btn-sm color-main py-0" title="View/Edit"><i class="fa fa-edit"></i></a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="custom-control custom-checkbox ml-2">
                        <input type="checkbox" class="custom-control-input" id="c6" name="checkbox">
                        <label class="custom-control-label" for="c6"></label>
                      </div>
                    </td>
                    <td>3214</td>
                    <td>43241010700001</td>
                    <td>Ullamco Laboris Nisi</td>
                    <td>K</td>
                    <td>6</td>
                    <td>Ea Commodo Consequat</td>
                    <td>Bary Back</td>
                    <td><span class="text-success"><i class="fa fa-circle mr-1"></i>OK</span></td>
                    <td>2020/07/08 - 08:51</td>
                    <td class="text-nowrap">
                      <a href="#" class="btn btn-sm color-main py-0" title="View/Edit"><i class="fa fa-edit"></i></a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="custom-control custom-checkbox ml-2">
                        <input type="checkbox" class="custom-control-input" id="c7" name="checkbox">
                        <label class="custom-control-label" for="c7"></label>
                      </div>
                    </td>
                    <td>4324</td>
                    <td>43241010700001</td>
                    <td>Duis Aute Irure</td>
                    <td>B</td>
                    <td>3</td>
                    <td>Dolor In Reprehenderit</td>
                    <td>John Doe</td>
                    <td><span class="text-success"><i class="fa fa-circle mr-1"></i>OK</span></td>
                    <td>2020/07/04 - 11:22</td>
                    <td class="text-nowrap">
                      <a href="#" class="btn btn-sm color-main py-0" title="View/Edit"><i class="fa fa-edit"></i></a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="custom-control custom-checkbox ml-2">
                        <input type="checkbox" class="custom-control-input" id="c8" name="checkbox">
                        <label class="custom-control-label" for="c8"></label>
                      </div>
                    </td>
                    <td>9455</td>
                    <td>43241010700001</td>
                    <td>Voluptate Velit</td>
                    <td>A</td>
                    <td>2</td>
                    <td>Esse Cillum</td>
                    <td>Mary Moe</td>
                    <td><span class="text-success"><i class="fa fa-circle mr-1"></i>OK</span></td>
                    <td>2020/07/01 - 08:58</td>
                    <td class="text-nowrap">
                      <a href="#" class="btn btn-sm color-main py-0" title="View/Edit"><i class="fa fa-edit"></i></a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="custom-control custom-checkbox ml-2">
                        <input type="checkbox" class="custom-control-input" id="c9" name="checkbox">
                        <label class="custom-control-label" for="c9"></label>
                      </div>
                    </td>
                    <td>3214</td>
                    <td>43241010700001</td>
                    <td>Dolore Eu Fugiat</td>
                    <td>A</td>
                    <td>1</td>
                    <td>Nulla Pariatur</td>
                    <td>July Dooley</td>
                    <td><span class="text-success"><i class="fa fa-circle mr-1"></i>OK</span></td>
                    <td>2020/06/21 - 11:25</td>
                    <td class="text-nowrap">
                      <a href="#" class="btn btn-sm color-main py-0" title="View/Edit"><i class="fa fa-edit"></i></a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="custom-control custom-checkbox ml-2">
                        <input type="checkbox" class="custom-control-input" id="c10" name="checkbox">
                        <label class="custom-control-label" for="c10"></label>
                      </div>
                    </td>
                    <td>4324</td>
                    <td>43241010700001</td>
                    <td>Sint Occaecat</td>
                    <td>W</td>
                    <td>2</td>
                    <td>Cupidatat</td>
                    <td>Doe John</td>
                    <td><span class="text-success"><i class="fa fa-circle mr-1"></i>OK</span></td>
                    <td>2020/06/09 - 23:21</td>
                    <td class="text-nowrap">
                      <a href="#" class="btn btn-sm color-main py-0" title="View/Edit"><i class="fa fa-edit"></i></a>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr><td colspan="11">
                    <div class="d-block d-md-flex flex-row justify-content-between">
                      <div class="d-block d-md-flex">
                      </div>
                      <ul class="pagination pagination-sm justify-content-justify-content-start justify-content-md-center my-10">
                        <li class="page-item disabled"><a class="page-link" href="#">&laquo;</a></li>
                        <li class="page-item active"><a class="page-link" href="#">1</a></li>
                        <li class="page-item"><a class="page-link" href="#">2</a></li>
                        <li class="page-item"><a class="page-link" href="#">3</a></li>
                        <li class="page-item"><a class="page-link" href="#">&raquo;</a></li>
                      </ul>
                      <div class="d-none d-md-flex"></div>
                    </div>
                  </td></tr>
                </tfoot>
              </table>
            </div>
  
          </div>
        </div>
  
      </div><!-- main-content -->
    </div><!-- .row -->
  </div>
</template>